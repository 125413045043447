.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #fff;
  background-image: url('./assets/images/bolt-orange.svg');
  background-repeat: repeat-x;
  background-position: top;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ef5d30;
}

.App-link {
  color: #ef5d30;
}