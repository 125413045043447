@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Open Sans', sans-serif;
}
.nav-menu {
    width: 100% !important;
}

@media only screen and (min-width: 360px) and (max-width: 375px) {
    .samsung-s {
        width: 160px !important;
    }
    .sm-text {
        font-size: 12px !important;
    }

    .reminder-text {
        font-size: 12px !important;
        padding-left: 65px !important;
    }

    .reminder-text-title {
        padding-left: 5px !important;
    }
    .about-doqit-content {
      word-spacing: -1px;
    }
    .user-profile-save-btn{
      padding-top: 5rem !important;
    }
    .user-profile-nav-bar{
      width: auto !important;
    }
    .faq-card-answer{
      width: 18rem !important;
    }
    .pin-document-mobile-view{
      margin: 23px 0px 0px 57px;
    }
}
@media only screen and (min-width: 468px) {
    .nav-menu {
        width: 415px !important;
    }
}

.menu-wrapper {
    @apply w-full h-[70px] relative
}

.max_height_screen_then_scroll{
    overflow-y: auto;
    height: calc(73vh - 5px);
}
.tns-anhor-text {
  color: rgb(17, 85, 204) !important;
  text-decoration: underline;
  font-weight: 400;
}
.privacy-policy-content {
  color: rgb(17, 85, 204) !important;
}

.tns-terms-of-use {
  text-decoration: unset !important;
}

@media only screen and (min-width: 390px) and (max-width: 844px) {

   .pin-document {
      margin: 23px 0px 0px 85px;
    }
}
.doqit-menu-gradient{
  position: fixed;
  width: 100%;
  height: 186px;
  left: 0px;
  bottom: -30px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E8EBF4 50%);
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  opacity: 0.9;
}